import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import Index from '../Home/Index';
const Dividends = () => {

    let accountType = localStorage.getItem('user-type');
    let user = localStorage.getItem('delox-user');
    if (user != null) {
        user = JSON.parse(user);
    }
    let theme = localStorage.getItem('CRAN CAPITAL LIMITED-Theme');
    if (theme === 'dark-mode') {
        theme = 'dark'
     }else{
      theme = 'light'
     }
     const [currentTheme, setCurrentTheme] = useState(theme);

    useEffect(() => {
        document.title = 'ADMIN DIVIDEND';
        if(window && window.init_template){
            window.init_template();
        } 
        
        window.addEventListener('themeChanged', function (e) {
            let theme = localStorage.getItem('CRAN CAPITAL LIMITED-Theme');
            if (theme !== null) {
                if (theme === 'dark-mode') {
                   setCurrentTheme('dark')
                }else{
                   setCurrentTheme('light') 
                }
               
            }             
       }, false);

    });
   return (
<div id="page-content">
<div className="row text-center mt-4 gy-4">
            
            <div className="col-6 mb-n2">
                <Link to="/" data-bs-toggle="offcanvas" data-bs-target="#topup-admin-dividends-edit" className="card card-style me-0" style={{height: "180px"}}>
                    <div className="card-center">
                        <span className="icon icon-xl rounded-m gradient-green shadow-bg shadow-bg-xs">
                            <i className="material-icons-outlined color-white" style={{fontSize: "28px", marginTop: "11px"}}>attach_money</i>
                            </span>
                        <h1 className="font-22 pt-3">Share funds</h1>
                    </div>
                    <div className="card-bottom">
                        <p className="font-11 mb-0 opacity-70">share dividedns</p>
                    </div>
                </Link>
            </div>
            <div className="col-6 mb-n2">
                <Link to="/" data-bs-toggle="offcanvas" data-bs-target="#topup-admin-balance-edit" className="card card-style ms-0" style={{height: "180px"}}>
                    <div className="card-center">
                        <span className="icon icon-xl rounded-m gradient-green shadow-bg shadow-bg-xs">
                            <i className="material-icons-outlined color-white" style={{fontSize: "28px", marginTop: "11px"}}>money_off_csred</i>
                            </span>
                        <h1 className="font-22 pt-3">Balance Accounts</h1>
                    </div>
                    <div className="card-bottom">
                        <p className="font-11 mb-0 opacity-70">Balance all accounts</p>
                    </div>
                </Link>
            </div>			
        </div>
        <Index/>
</div>
  )
}

export default Dividends