import React, {useState, useEffect} from 'react'
import Chart from 'react-apexcharts'
import { accountBalance } from '../Request/Wallets';
import { investmentChartHistory } from '../Request/Histories';
import Clock from '../Investment/Clock';
const ChartBalance = () => {

  const [chartHistory, setChartHistory] = useState(investmentChartHistory());
   let mySeries = []

  const fillChart = () => {
    if (chartHistory.length > 0) {
      chartHistory.forEach(investment => {
        if (investment.earning !== null) {
          let myData = {
            name: 'Earning for '+ getAmount(investment.amount),
            data: JSON.parse(investment.earning_record)
          }
          mySeries.push(myData)
        }
      });
    }else{
      let myData = {
        name: 'Earnings',
        data: [31, 40, 28, 51, 42, 50, 60]
      };

      let myInvest=  {
          name: 'Percentages',
          data: [11, 32, 45, 32, 34, 52, 41]
      }
      mySeries.push(myData)
      mySeries.push(myInvest)
    }
    


    setChartData({...chartData, 
      
      series: mySeries,

          options: {
            chart: {
              height: 150,
              type: 'area',
              toolbar: {
                autoSelected: "pan",
                show: false
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            legend: {
              show: true,
            },
            grid: {
                show: false,
            },
            xaxis: {
                labels: {
                    show: false
                },
              type: 'category',
              categories: []
            },
            yaxis: {
                show: false
            },
            tooltip: {
              x: {
                format: 'dd/MM/yy HH:mm'
              },
              fillSeriesColor: '#000000',
            },
          },
    })
  }

  const myChartHistory = () => {
    let accountHistory = [];
    let storedHistory = localStorage.getItem('binaryfair-account-histories');
    if (storedHistory !== null) {
        let data = JSON.parse(storedHistory);
        accountHistory = data.investmentHistory;
    }
    return accountHistory;
}

    const initialChart = {
          
        series: mySeries,

          options: {
            chart: {
              height: 150,
              type: 'area',
              toolbar: {
                autoSelected: "pan",
                show: false
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            grid: {
                show: false,
            },
            legend: {
              show: true,
              showForSingleSeries: true,
              showForNullSeries: true,
              showForZeroSeries: true,
              position: 'bottom',
            },
            xaxis: {
                labels: {
                    show: false
                },
              type: 'datetime',
              categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
            },
            yaxis: {
                show: false
            },
            tooltip: {
              x: {
                format: 'dd/MM/yy HH:mm'
              },
            },
          },
          
    }


    const [accBalance, setaccountBalance] = useState(accountBalance());
    const [chartData, setChartData] = useState(initialChart);


       const newaccountBalance = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = parseFloat(data.account_balance);
        }
        return balance;
    }

    useEffect(() => {
        fillChart();
        document.title = 'charts';
        if(window && window.init_template){
            window.init_template();
        } 

       window.addEventListener('updatedStorage', function (e) {
        setaccountBalance(newaccountBalance());
        setChartHistory(myChartHistory());
        fillChart();
        
  }, false);
    }, []);
    const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);
    const {options, series} = chartData;
  return (
    <div className='container'>
        <div className='card p-0 shadow-sm color-theme' style={{borderRadius: '15px'}}>
        <div style={{transform: 'translate(0px, 30px)'}} className='text-center fw-bolder'>Withdrawable Balance</div>
        <h2 style={{transform: 'translate(0px, 40px)'}} className='text-center font-36'>{getAmount(accBalance)}</h2>
        <Chart options={options} series={series} type="area" height={150} />      
        {/* <Clock/> */}
        </div>
    </div>
  )
}

export default ChartBalance