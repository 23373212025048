import React, {useEffect, useState, Fragment} from 'react';
import { Link } from "react-router-dom";
import {useMediaQuery} from '@react-hook/media-query'
import Analysis from './Analysis';
import { accountBalance, referralBalance, bonusBalance, currentInvestment, profitBalance } from '../Request/Wallets';
import {currentEarning} from '../Request/Wallets';
import Clock from '../Investment/Clock';
import { plansHistory, investmentsHistory } from '../Request/Histories';
import { MiniChart } from 'react-ts-tradingview-widgets';
import ChartBalance from './ChartBalance';

const Dashboard = () => {
    const switchEye = (name) => {
        let element = document.getElementById(name);
        let eye  = element.innerHTML;
        console.log(eye);
        if (eye === 'visibility') {
            element.innerHTML = 'visibility_off';
        }else{
            element.innerHTML = 'visibility';
        }

    }
    const [accBalance, setaccountBalance] = useState(accountBalance());
    const [refBalance, setreferralBalance] = useState(referralBalance());
    const [profitBal, setProfitBal] = useState(profitBalance());
    const [bonBalance, setbonusBalance] = useState(bonusBalance());
    const [currentInvested, setcurrentInvested] = useState(currentInvestment());
    const [investments, setInvestments] = useState(investmentsHistory());

       const newaccountBalance = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = parseInt(data.account_balance);
        }
        return balance;
    }
    
    const newreferralBalance = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = parseInt(data.ref_bal);
        }
        return balance;
    
    }

    const newProfitBalance = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = parseInt(data.profit_balance);
        }
        return balance;
    
    }

    const newInvestmentHistory = () => {
        let accountHistory = [];
        let storedHistory = localStorage.getItem('binaryfair-account-histories');
        if (storedHistory !== null) {
            let data = JSON.parse(storedHistory);
            accountHistory = data.investments;
        }
        return accountHistory;
    }

    const newbonusBalance = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = data.bonus_balanace;
        }
        return balance;
      }

      const newCurrentInvestment = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = data.current_investment;
        }
        return balance;
      }


    let accountType = localStorage.getItem('user-type');
    let user = localStorage.getItem('delox-user');
    if (user != null) {
        user = JSON.parse(user);
    }
    let theme = localStorage.getItem('CRAN CAPITAL LIMITED-Theme');
    if (theme === 'dark-mode') {
        theme = 'dark'
     }else{
      theme = 'light'
     }
     const [currentTheme, setCurrentTheme] = useState(theme);

    const matches = useMediaQuery('only screen and (max-width: 480px)')
    useEffect(() => {
        document.title = 'DASHBOARD';
        if(window && window.init_template){
            window.init_template();
        } 
        
        window.addEventListener('themeChanged', function (e) {
            let theme = localStorage.getItem('CRAN CAPITAL LIMITED-Theme');
            if (theme !== null) {
                if (theme === 'dark-mode') {
                   setCurrentTheme('dark')
                }else{
                   setCurrentTheme('light') 
                }
               
            }             
       }, false);

       window.addEventListener('updatedStorage', function (e) {
        setaccountBalance(newaccountBalance());
        setreferralBalance(newreferralBalance());
        setbonusBalance(newbonusBalance());
        setcurrentInvested(newCurrentInvestment());
        setInvestments(newInvestmentHistory());
        setProfitBal(newProfitBalance());        
        
  }, false);
    });
const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);
   return (
<div id="page-content">   

    {/* <!-- Quick Actions --> */}
   
    <ChartBalance/>
    
    {/* <div className='position-relative z-index-2 bg-theme' style={{height: "20px", width: "50%", transform: "translate(0px, -25px)", marginLeft: "20%"}}></div> */}

    <div className='container mb-4 position-relative z-index-2' >
        
        <div className="row text-center">        
            <div className="col-6 mb-n2 text-start p-2">
                <div className="card m-0 card-style bg-theme shadow-card shadow-card-m shadow-sm" style={{height: "110px", borderRadius: "10px"}}>
                    <div className="card-center">
                        <div onClick={()=>switchEye('eye1')} className="bg-theme color-theme px-3 py-2 rounded-end d-inline-block">
                            <h1 className="font-13 my-n1">
                                <a className="color-theme" data-bs-toggle="collapse" href="#fundingWallet1" aria-controls="fundingWallet1">Current Investment <br/><i id='eye1' translate="no" className="material-icons-sharp" style={{fontSize: "28px"}}>visibility_off</i></a>
                            </h1>
                            <div className="collapse" id="fundingWallet1"><h2 className="color-theme font-26">{getAmount(currentInvested)}</h2></div>
                        </div>
                    </div>
                    <div className="card-overlay "></div>
                </div>
            </div>

            <div className="col-6 mb-n2 text-start p-2">
                <div className="card m-0 card-style bg-theme shadow-card shadow-card-m shadow-sm" style={{height: "110px", borderRadius: "10px"}}>
                    <div className="card-center">
                        <div onClick={()=>switchEye('eye2')} className="bg-theme color-theme px-3 py-2 rounded-end d-inline-block">
                            <h1 className="font-13 my-n1">
                                <a className="color-theme" data-bs-toggle="collapse" href="#fundingWallet2" aria-controls="fundingWallet2">Total Balance <br/>
                                <i translate="no" id='eye2' className="material-icons-sharp" style={{fontSize: "28px"}}>visibility_off</i></a>
                            </h1>
                            <div className="collapse" id="fundingWallet2"><h2 className="color-theme font-26">{getAmount(accBalance + currentInvested)}</h2></div>
                        </div>
                    </div>
                    <div className="card-overlay "></div>
                </div>
            </div>

            <div className="col-6 mb-n2 text-start p-2">
                <div className="card m-0 card-style bg-theme shadow-card shadow-card-m shadow-sm" style={{height: "110px", borderRadius: "10px"}}>
                    <div className="card-center">
                        <div onClick={()=>switchEye('eye3')} className="bg-theme color-theme px-3 py-2 rounded-end d-inline-block">
                            <h1 className="font-13 my-n1">
                                <a className="color-theme" data-bs-toggle="collapse" href="#fundingWallet4" aria-controls="fundingWallet4">Profit Earned<br/>
                                <i translate="no" id='eye3' className="material-icons-sharp" style={{fontSize: "28px"}}>visibility_off</i></a>
                            </h1>
                            <div className="collapse" id="fundingWallet4"><h2 className="color-theme font-26">{getAmount(profitBal)}</h2></div>
                        </div>
                    </div>
                    <div className="card-overlay "></div>
                </div>
            </div>

            <div className="col-6 mb-n2 text-start p-2">
                <div className="card m-0 card-style bg-theme shadow-card shadow-card-m shadow-sm" style={{height: "110px", borderRadius: "10px"}}>
                    <div className="card-center">
                        <div onClick={()=>switchEye('eye4')} className="bg-theme color-theme px-3 py-2 rounded-end d-inline-block">
                            <h1 className="font-13 my-n1">
                                <a className="color-theme" data-bs-toggle="collapse" href="#fundingWallet5" aria-controls="fundingWallet5">Referral Bonus<br/>
                                <i translate="no" id='eye4' className="material-icons-sharp" style={{fontSize: "28px"}}>visibility_off</i></a>
                            </h1>
                            <div className="collapse" id="fundingWallet5"><h2 className="color-theme font-26">{getAmount(refBalance)}</h2></div>
                        </div>
                    </div>
                    <div className="card-overlay "></div>
                </div>
            </div>	
	
        </div>
    </div>
    
    
    {investments.length > 0 && <div className="container">
        
                {/* <Link to="#" className="d-flex p justify-content-between mb-2 border-bottom pb-4">
                   
                    <div className="align-self-center border-left">
                        <h5 className="pt-1 mb-n1 font-14">Amount</h5>
                    </div>
                    <div className="align-self-center border-left">
                        <h5 className="pt-1 mb-n1 font-14">Earning </h5>
                    </div>
                    <div className="align-self-center border-left">
                    <h5 className="pt-1 mb-n1 font-14">Percentage</h5>
                    </div>
                </Link> */}
                {investments.map(investment => (
                    
                    <Fragment key={investment.id}>
                        <div className="card pt-2 px-0 rounded shadow-sm mb-4">
                            <div className="content mt-0 mb-2">
                                {(investment.status === 'approved' || investment.status === 'completed') && 
                                <Link to="#"  className="d-flex p justify-content-between mb-2">
                                    {/* <div className="align-self-center border-left">
                                        <h5 className="pt-1 mb-n1 font-14 ">{investment.created_at}</h5>
                                    </div> */}
                                    <div className="align-self-center border-left ">
                                        <div className='color-theme font-11 fw-bold'>Amount</div>
                                        <h5 className="pt-1 mb-n1 font-16">{getAmount(investment.amount)}</h5>
                                    </div>
                                    <div className="align-self-center border-left ">
                                        <div className='color-theme font-11 fw-bold'>Profit</div>
                                        <h5 className="pt-1 mb-n1 font-16 text-success">{getAmount(investment.earning)}</h5>
                                    </div>

                                    <div className="align-self-center ">
                                        <div className='color-theme font-11 fw-bold'>Percentage</div>
                                        <h5 className="pt-1 mb-n1 font-16 text-success">{getAmount(investment.earned_percentage)}%</h5>
                                    </div>
                                    {/* {investment.amount_copy > investment.amount &&
                                    <div className="align-self-center border-left ">
                                    <div className='color-theme font-11 fw-bold'>Compounded</div>
                                    <h5 className="pt-1 mb-n1 font-16 text-danger">{getAmount(investment.amount_copy)}</h5>
                                    </div>
                                    } */}
                                    
                                </Link>
                                }
                            </div>           
                        </div>
                    </Fragment>
                    ))}
                </div> 
    }
     
    
     <Analysis/> 

</div>
  )
}

export default Dashboard