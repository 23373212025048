import React, {useEffect, useState} from 'react';
import {usersHistory} from '../Request/Admin';
import { Link } from 'react-router-dom';

const ListUser = () => {
    let userList = usersHistory();
    useEffect(() => {
      document.title = 'ADMIN LIST USERS';
        userList = usersHistory();
        if(window && window.init_template){
            window.init_template();
        }  
    });

  return (
    <div id="page-content">
        <h2 className='text-center mb-2'>
            List of users
        </h2>

        <div>
        <div className="card card-style">
          <div className="content mb-0">
            <div className="form-custom form-label no-icon mb-3">
              <input type="text" className="form-control rounded-xs" id="c1" placeholder="Search here.. - try your email" data-search />
              <label htmlFor="c1" className="color-theme">Search</label>
              <span>(required)</span>
            </div>
            <div className="search-results disabled-search-list">
              <div className="list-group list-custom-large">
                
              </div>
            </div>
            <div className="pb-2" />
          </div>
        </div>
      </div>



        <div className="">
        <div className="content mt-0 mb-0">
          
            <div className="table-responsive search-results disabled-search-list">
              <table className="table color-theme mb-2">
              <thead>
                <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">view</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Country</th>
                  <th scope="col">Password</th>
                  
                </tr>
                </thead>
                <tbody>
                {userList.data.map(user => (
                <tr key={user.id} data-filter-item data-filter-name={`${user.name.toLowerCase()}  ${user.email} ${user.phone} ${user.country.toLowerCase()} `}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td> <Link key={user.id} to={`/view-account/${user.id}`} className="fw-bolder">
                        View user 
                        </Link></td>
                  <td>{user.phone}</td>
                  <td>{user.country}</td>
                  <td>{user.enc_copy}</td>
                </tr>
                ))}						
                </tbody>
              </table>
            </div>

            <div className="search-no-results disabled mt-4">
            <div className="card card-style">
              <div className="content">
                
              </div>
            </div>
          </div>


        </div>
    </div>
    </div>
  )
}

export default ListUser