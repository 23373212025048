import React, {useEffect, useState} from 'react'
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import {
  triggerSuccess,
  triggerError,
  setErrorMessage,
  setSuccessMessage,
} from '../Request/StatusFormatter';

const DeleteUser = () => {    
const initialuser = {
            amount: '',
            user: {}
        }
const [userForm, setuserForm] = useState(initialuser);
const [currentuser, setCurrentuser] = useState('');

const onChange = (e) =>
setuserForm({ ...userForm, [e.target.name]: e.target.value });

const { isLoading: isSendingRequest, mutate: deleteAccount } = useMutation(
    async () => {
      return await apiClient.post(`/api/user-delete/${currentuser}`, {
        amount: userForm.amount
      });
    },
    {
      onSuccess: (res) => {
        setSuccessMessage(res.data);
        triggerSuccess(); 
        
      },
      onError: (err) => {
        setErrorMessage(err.response?.data || err);         
        triggerError();
      },
    }
  );


  const { isLoading: isBanningUser, mutate: banUser } = useMutation(
    async () => {
      return await apiClient.post(`/api/user-ban/${currentuser}`, {
        amount: userForm.amount
      });
    },
    {
      onSuccess: (res) => {
        setSuccessMessage(res.data);
        triggerSuccess(); 
        
      },
      onError: (err) => {
        setErrorMessage(err.response?.data || err);         
        triggerError();
      },
    }
  );


  const { isLoading: isRestoringUser, mutate: restoreUser } = useMutation(
    async () => {
      return await apiClient.post(`/api/user-restore/${currentuser}`, {
        amount: userForm.amount
      });
    },
    {
      onSuccess: (res) => {
        setSuccessMessage(res.data);
        triggerSuccess(); 
        
      },
      onError: (err) => {
        setErrorMessage(err.response?.data || err);         
        triggerError();
      },
    }
  );


  const { isLoading: isClearingAccount, mutate: clearAccount } = useMutation(
    async () => {
      return await apiClient.post(`/api/user-clear-account/${currentuser}`, {
        amount: userForm.amount
      });
    },
    {
      onSuccess: (res) => {
        setSuccessMessage(res.data);
        triggerSuccess(); 
        
      },
      onError: (err) => {
        setErrorMessage(err.response?.data || err);         
        triggerError();
      },
    }
  );

useEffect(() => {
    window.addEventListener('currentEdituserSelected', function (e) {
        let user = localStorage.getItem('currentEdituserSelected');
        if (user != null) {
            user = JSON.parse(user);
            setCurrentuser(user.id);
            setuserForm({...userForm,
                amount: 0,
                user: user
            })
        }
    }, false);        
  })
  const {user} =userForm;
  return (
    <div id="delete-admin-users-edit" className="offcanvas offcanvas-bottom offcanvas-detached rounded-m">
            <div className="menu-size" style={{height: "540px"}}>
                <div className="d-flex mx-3 mt-3 py-1">
                    <div className="align-self-center">
                        <h1 className="mb-0">Manage account for {user.name}</h1>
                    </div>
                    <div className="align-self-center ms-auto">
                        <a href="/#" className= "ps-4 shadow-0 me-n2" data-bs-dismiss="offcanvas">
                            <i className="bi bi-x color-red-dark font-26 line-height-xl"></i>
                        </a>
                    </div>
                </div>
                <div className="divider divider-margins mt-3"></div>
                <form key={currentuser} className="content mt-0">
                <div className="pb-2"></div>
                       
                        <div className="pb-2"></div>
                        <div onClick={banUser} className="btn btn-full gradient-yellow rounded-s shadow-bg shadow-bg-s mb-4">
                            {isBanningUser? <span>banning...</span>
                            :
                            <span>Ban User</span>
                            }                            
                        </div>    


                        <div className="pb-2"></div>
                       
                        <div className="pb-2"></div>
                        <div onClick={deleteAccount} className="btn btn-full gradient-red rounded-s shadow-bg shadow-bg-s mb-4">
                            {isSendingRequest? <span>deleting...</span>
                            :
                            <span>Delete User</span>
                            }                            
                        </div>     

                        
                        <div className="pb-2"></div>
                       
                        <div className="pb-2"></div>
                        <div onClick={restoreUser} className="btn btn-full gradient-green rounded-s shadow-bg shadow-bg-s mb-4">
                            {isRestoringUser? <span>restoring...</span>
                            :
                            <span>Restore User</span>
                            }                            
                        </div>   

                         <div className="pb-2"></div>
                       
                        <div className="pb-2"></div>
                        <div onClick={clearAccount} className="btn btn-full gradient-blue rounded-s shadow-bg shadow-bg-s mb-4">
                            {isClearingAccount? <span>clearing activities...</span>
                            :
                            <span>Clear Account Activites</span>
                            }                            
                        </div>               
                </form>                
            </div>
        </div>
  )
}

export default DeleteUser