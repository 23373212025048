import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import {useMediaQuery} from '@react-hook/media-query'
import Analysis from './Analysis';
import { plansHistory, investmentsHistory } from '../Request/Histories';
import { totalProfits } from '../Request/Wallets';
import ChartBalance from './ChartBalance';

const Index = () => {
    const [plans, setPlans] = useState(plansHistory());
    const [investments, setInvestments] = useState(investmentsHistory());

    const [grossProfit, setaccountGrossProfit] = useState(totalProfits());

    const newPlansHistory = () => {
        let accountHistory = [];
        let storedHistory = localStorage.getItem('binaryfair-account-histories');
        if (storedHistory !== null) {
            let data = JSON.parse(storedHistory);
            accountHistory = data.plans;
        }
        return accountHistory;
    
    }

    const newInvestmentHistory = () => {
        let accountHistory = [];
        let storedHistory = localStorage.getItem('binaryfair-account-histories');
        if (storedHistory !== null) {
            let data = JSON.parse(storedHistory);
            accountHistory = data.investments;
        }
        return accountHistory;
    }

    const newGrossProfit = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = parseInt(data.total_profits);
        }
        return balance;
    
    }

    let accountType = localStorage.getItem('user-type');
    let user = localStorage.getItem('delox-user');
    if (user != null) {
        user = JSON.parse(user);
    }
    let theme = localStorage.getItem('CRAN CAPITAL LIMITED-Theme');
    if (theme === 'dark-mode') {
        theme = 'dark'
     }else{
      theme = 'light'
     }
     const [currentTheme, setCurrentTheme] = useState(theme);

    const matches = useMediaQuery('only screen and (max-width: 480px)')
    useEffect(() => {
        document.title = 'DASHBOARD';
        if(window && window.init_template){
            window.init_template();
        } 
        
        window.addEventListener('themeChanged', function (e) {
            let theme = localStorage.getItem('CRAN CAPITAL LIMITED-Theme');
            if (theme !== null) {
                if (theme === 'dark-mode') {
                   setCurrentTheme('dark')
                }else{
                   setCurrentTheme('light') 
                }
               
            }             
       }, false);

       window.addEventListener('updatedStorage', function (e) {
        setPlans(newPlansHistory());
        setaccountGrossProfit(newGrossProfit());
        setInvestments(newInvestmentHistory());
        }, false);

    });
const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);
   return (
<div id="page-content">
    <div className='container'>
        <div className="row">
            {accountType === 'admin' &&
            <div className="col-12 my-4">
                <div className="card text-center p-6">
                    <h4 className='text-success'>Currently using Admin account for <br/> {user.name}</h4>
                </div>            
            </div> 
            }     
        </div>  
        
        {/* <TechnicalAnalysis symbol='BITSTAMP:BTCUSD' colorTheme={currentTheme} width="100%"></TechnicalAnalysis>
        <div className='bg-theme' style={{height: "35px", marginTop: "-40px", position: "relative", zIndex: "20"}}></div> */}
    </div> 
    {/* <!-- Quick Actions --> */}
    {accountType !== 'admin' && 
    <div className="content py-2">
        <div className="d-flex text-center">
            <div className="me-auto">
                <Link to="/user-account" className="icon icon-xxl rounded-circle bg-theme shadow-m color-theme"><i translate="no" className="material-icons-outlined" style={{fontSize: "30px", marginTop: "18px"}}>dashboard</i></Link>
                <h6 className="font-13 opacity-80 font-500 mb-0 pt-2">Dashboard</h6>
            </div>
            <div className="m-auto">
                <Link to="/withdraw" data-bs-toggle="offcanvas" data-bs-target="#menu-withdrawal" className="icon icon-xxl rounded-circle bg-theme shadow-m color-theme"><i translate="no" className="material-icons-outlined" style={{fontSize: "30px", marginTop: "18px"}}>money_off_csred</i></Link>
                <h6 className="font-13 opacity-80 font-500 mb-0 pt-2">Withdraw</h6>
            </div>
            <div className="m-auto">
                <Link to="/referral" className="icon icon-xxl rounded-circle bg-theme shadow-m color-theme"><i translate="no" className="material-icons-outlined" style={{fontSize: "30px", marginTop: "18px"}}>insights</i></Link>
                <h6 className="font-13 opacity-80 font-500 mb-0 pt-2">Trade</h6>
            </div>
            <div className="ms-auto">
                <Link to="/market" className="icon icon-xxl rounded-circle bg-theme shadow-m color-theme"><i translate="no" className="material-icons-outlined" style={{fontSize: "30px", marginTop: "18px"}}>candlestick_chart</i></Link>
                <h6 className="font-13 opacity-80 font-500 mb-0 pt-2">Market</h6>
            </div>
        </div>
    </div> 
    }
    {/* <div className='container'>
    <div className="card m-0 mb-8 card-style bg-5 shadow-card shadow-card-l shadow-sm mt-n3" style={{height:"150px", borderRadius: "5px", backgroundImage: "url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1647860735/card4.jpg)"}}>
            <div className="card-top p-3">
                <a href="/#" onClick={ (event) => event.preventDefault() } data-bs-toggle="offcanvas" data-bs-target="#menu-withdrawal" className="icon icon-xxs bg-theme color-theme float-end"><i className="bi bi-three-dots font-18"></i></a>
            </div>
            <div className="card-center">
                <div className="bg-theme px-3 py-2 rounded-end d-inline-block">
                    <h1 className="font-13 my-n1">
                        <a className="color-theme" data-bs-toggle="collapse" href="#balance3" aria-controls="balance2">View Amount</a>
                    </h1>
                    <div className="collapse" id="balance3"><h2 className="color-theme font-26">{getAmount(grossProfit)}</h2></div>
                </div>
            </div>
            <strong className="card-top no-click font-12 p-3 color-white font-monospace">Total Gross profit Shared for Today</strong>
            <strong className="card-bottom no-click p-3 text-start color-white font-monospace">updated now</strong>
            <strong className="card-bottom no-click p-3 text-end color-white font-monospace">{new Date().toLocaleTimeString()}</strong>
            <div className="card-overlay "></div>
        </div>
    </div> */}
    <Analysis/>   
    {/* <div className='container'><div className='px-2'>current dividend distribution system</div></div> */}
    {/* {plans.map(plan =>(
        <div className="container" key={plan.id}>
        <div className="card pt-2 px-0 rounded-2 shadow-sm mb-4">
            <h2 className='text-center mb-2 text-secondary font-14'>{plan.name}</h2>
            <div className="content mt-0 mb-2">
                <Link to="#" className="d-flex p justify-content-between mb-2 border-bottom pb-4">
                    <div className="align-self-center">
                        <h5 className="pt-1 mb-n1 font-14">Trader</h5>
                    </div>
                    <div className="align-self-center">
                        <h5 className="pt-1 mb-n1 font-14">Share</h5>
                    </div>
                    <div className="align-self-center">
                        <h5 className="pt-1 mb-n1 font-14">Amount </h5>
                    </div>
                    <div className="align-self-center">
                    <h5 className="pt-1 mb-n1 font-14">Percentage</h5>
                    </div>
                </Link>
                {plan.dividends.map(dividend => (
                    <Link to="#" key={dividend.id} className="d-flex p justify-content-between mb-2">
                    <div className="align-self-center">
                        <h5 className="pt-1 mb-n1 font-14 ">{dividend.investment.user.name}</h5>
                    </div>
                    <div className="align-self-center ">
                        <h5 className="pt-1 mb-n1 font-14">{getAmount(dividend.amount)}</h5>
                    </div>
                    <div className="align-self-center ">
                        <h5 className="pt-1 mb-n1 font-14 text-danger">{getAmount(dividend.total_share)}</h5>
                    </div>
                    <div className="font-14 btn gradient-green p-2" style={{height: "30px", width: "90px", borderRadius: "2px"}}>
                    {dividend.percentage}%
                    </div>
                </Link>
                ))}
            </div>
            <div className='text-center'>
                <Link to='/invest' className='text-success'>
                    Participate
                </Link>
            </div>            
        </div>
    </div>  
    ))} */}
     
       
    
</div>
  )
}

export default Index