import React, {useState} from 'react'
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import {
  triggerSuccess,
  triggerError,
  setErrorMessage,
  setSuccessMessage,
} from '../Request/StatusFormatter';

const CreatePlan = () => {    
const initialplan = {
    min_amount: '',
    max_amount: '',
    durations_copy: '',
    name: '',
    ref_bonus: '',
    description: '',
    daily_roi: '',
    max_daily_roi: ''
}
const [planForm, setplanForm] = useState(initialplan);

const onChange = (e) =>
setplanForm({ ...planForm, [e.target.name]: e.target.value });

const { isLoading: isSendingRequest, mutate: postplan } = useMutation(
    async () => {
      return await apiClient.post(`/api/create-plan`, {
        min_amount: planForm.min_amount,
        max_amount: planForm.max_amount,
        durations_copy: planForm.durations_copy,
        name: planForm.name,
        ref_bonus: planForm.ref_bonus,
        description: planForm.description,
        daily_roi: planForm.daily_roi,
        max_daily_roi: planForm.max_daily_roi
      });
    },
    {
      onSuccess: (res) => {
        setSuccessMessage(res.data);
        triggerSuccess(); 
        
      },
      onError: (err) => {
        setErrorMessage(err.response?.data || err);         
        triggerError();
      },
    }
  );

  return (
    <div id="admin-plan-create-menu" className="offcanvas offcanvas-bottom offcanvas-attached">
            <div className="menu-size" style={{height: "640px"}}>
                <div className="d-flex mx-3 mt-3 py-1">
                    <div className="align-self-center">
                        <h1 className="mb-0">Create New Plan</h1>
                    </div>
                    <div className="align-self-center ms-auto">
                        <a href="/#" className= "ps-4 shadow-0 me-n2" data-bs-dismiss="offcanvas">
                            <i className="bi bi-x color-red-dark font-26 line-height-xl"></i>
                        </a>
                    </div>
                </div>
                <div className="divider divider-margins mt-3"></div>
                <form className="content mt-0">
                <div className="pb-2"></div>
                        <div className="form-custom form-label form-icon">
                            <i className="bi bi-code font-14"></i>
                            <input onChange={onChange}  type="text" name="min_amount" className="form-control rounded-xs bg-theme color-theme" id="name" placeholder="minimum amount"/>
                            <label htmlFor="min_amount" className="color-highlight form-label-always-">Minimum amount</label>
                            <span>(minimum amount)</span>
                        </div>
                        <div className="pb-2"></div>

                        <div className="form-custom form-label form-icon">
                            <i className="bi bi-code font-14"></i>
                            <input onChange={onChange}  type="text" name="max_amount" className="form-control rounded-xs bg-theme color-theme" id="max_amount" placeholder="Maximum amount"/>
                            <label htmlFor="max_amount" className="color-highlight form-label-always-">Maximum Amount</label>
                            <span>(Maximum Amount)</span>
                        </div>
                        <div className="pb-2"></div>

                        <div className="form-custom form-label form-icon">
                            <i className="bi bi-code font-14"></i>
                            <textarea onChange={onChange}  type="text" name="durations_copy" className="form-control rounded-xs bg-theme color-theme" id="durations_copy" placeholder="durations"></textarea>
                            <label htmlFor="durations_copy" className="color-highlight form-label-always-">durations</label>
                            <span>(durations)</span>
                        </div>
                        <div className="pb-2"></div>


                        <div className="form-custom form-label form-icon">
                            <i className="bi bi-code font-14"></i>
                            <input onChange={onChange}  type="text" name="name" className="form-control rounded-xs bg-theme color-theme" id="name" placeholder="namet"/>
                            <label htmlFor="name" className="color-highlight form-label-always-">Name</label>
                            <span>(name)</span>
                        </div>
                        <div className="pb-2"></div>

                        <div className="form-custom form-label form-icon">
                            <i className="bi bi-code font-14"></i>
                            <input onChange={onChange}  type="text" name="ref_bonus" className="form-control rounded-xs bg-theme color-theme" id="ref_bonus" placeholder="Referral Bonus"/>
                            <label htmlFor="ref_bonus" className="color-highlight form-label-always-">Referral Bonus</label>
                            <span>(Referral Bonus)</span>
                        </div>
                        <div className="pb-2"></div>

                        <div className="form-custom form-label form-icon">
                            <i className="bi bi-code font-14"></i>
                            <textarea onChange={onChange}  type="text" name="description" className="form-control rounded-xs bg-theme color-theme" id="description" placeholder="description"></textarea>
                            <label htmlFor="description" className="color-highlight form-label-always-">description</label>
                            <span>(description)</span>
                        </div>
                        <div className="pb-2"></div>

                        <div className="form-custom form-label form-icon">
                            <i className="bi bi-code font-14"></i>
                            <input onChange={onChange}  type="text" name="daily_roi" className="form-control rounded-xs bg-theme color-theme" id="daily_roi" placeholder="Daily ROI"/>
                            <label htmlFor="daily_roi" className="color-highlight form-label-always-">Daily ROI</label>
                            <span>(Daily ROI)</span>
                        </div>
                        <div className="pb-2"></div>

                        <div className="pb-2"></div>

                        <div className="form-custom form-label form-icon">
                            <i className="bi bi-code font-14"></i>
                            <input onChange={onChange} type="text" name="max_daily_roi" className="form-control rounded-xs bg-theme color-theme" id="max_daily_roi" placeholder="Max Daily ROI"/>
                            <label htmlFor="max_daily_roi" className="color-highlight form-label-always-">Max Daily ROI</label>
                            <span>(Max Daily ROI)</span>
                        </div>
                        <div className="pb-2"></div>


                        <div onClick={postplan} className="btn btn-full gradient-green rounded-s shadow-bg shadow-bg-s mb-4">
                            {isSendingRequest? <span>creating...</span>
                            :
                            <span>Create plan</span>
                            }                            
                        </div>                     
                </form>                
            </div>
        </div>
  )
}

export default CreatePlan