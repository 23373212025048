import React, {useEffect, useState} from 'react'
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import {
  triggerSuccess,
  triggerError,
  setErrorMessage,
  setSuccessMessage,
} from '../Request/StatusFormatter';

const Balance = () => {    
const initialuser = {
            amount: '',
            user: {}
        }
const [userForm, setuserForm] = useState(initialuser);
const [currentuser, setCurrentuser] = useState('');


const { isLoading: isSendingRequest, mutate: postuser } = useMutation(
    async () => {
      return await apiClient.post(`/api/balance-dividend`);
    },
    {
      onSuccess: (res) => {
        setSuccessMessage(res.data);
        triggerSuccess(); 
        
      },
      onError: (err) => {
        setErrorMessage(err.response?.data || err);         
        triggerError();
      },
    }
  );


  const { isLoading: isDeleting, mutate: deleteDividends } = useMutation(
    async () => {
      return await apiClient.post(`/api/delete-dividend`);
    },
    {
      onSuccess: (res) => {
        setSuccessMessage(res.data);
        triggerSuccess(); 
        
      },
      onError: (err) => {
        setErrorMessage(err.response?.data || err);         
        triggerError();
      },
    }
  );

useEffect(() => {
    window.addEventListener('currentEdituserSelected', function (e) {
        let user = localStorage.getItem('currentEdituserSelected');
        if (user != null) {
            user = JSON.parse(user);
            setCurrentuser(user.id);
            setuserForm({...userForm,
                amount: 0,
                user: user
            })
        }
    }, false);        
  })
  const {user} =userForm;
  return (
    <div id="topup-admin-balance-edit" className="offcanvas offcanvas-bottom offcanvas-detached rounded-m">
            <div className="menu-size" style={{height: "540px"}}>
                <div className="d-flex mx-3 mt-3 py-1">
                    <div className="align-self-center">
                        <h1 className="mb-0">MANAGE EARNINGS</h1>
                    </div>
                    <div className="align-self-center ms-auto">
                        <a href="/#" className= "ps-4 shadow-0 me-n2" data-bs-dismiss="offcanvas">
                            <i className="bi bi-x color-red-dark font-26 line-height-xl"></i>
                        </a>
                    </div>
                </div>
                <div className="divider divider-margins mt-3"></div>
                <div className="content mt-0">
                <div className="pb-2 alert alert-warning">Note that this will clear all the current dividends registered in the system and credit all the earnings to their respective investments which will be withdrawable upon contract expiration</div>                        
                    <div onClick={postuser} className="btn btn-full gradient-green rounded-s shadow-bg shadow-bg-s mb-4">
                        {isSendingRequest ? <span>Crediting...</span>: <span>Credit All earnings</span>}
                        
                    </div>   

                    <div className="pb-2 alert alert-warning">Note that this will clear all the current dividends registered in the without affecting the investments balance, this is useful for manipulating the current earnings of users before making your final decision</div>  

                    <div onClick={deleteDividends} className="btn btn-full gradient-red rounded-s shadow-bg shadow-bg-s mb-4 mt-4">
                        {isDeleting ? <span>Deleting...</span>: <span>Delete current devidends</span>}
                        
                    </div>                   
                </div>                
            </div>
        </div>
  )
}

export default Balance